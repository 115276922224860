import React from 'react'
// import ServiceSidebar from './ServiceSidebar'
// import details1 from '../../assets/images/services/services-details1.jpg'
// import project2 from '../../assets/images/projects/project2.jpg'
// import charts from '../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        
                    </div>

                    <div className="col-lg-4 col-md-12">
                 
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent