import React from 'react'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import ServiceDetailsContent from '../components/ServiceDetails/ServiceDetailsContent'

 
const Details = () => {
    return (
        <Layout>
           <div className="darknav"><Navbar /></div>
            <PageBanner
                pageTitle="Service Details" 
                homePageText="Home" 
                homePageUrl="/" 
                activePageText="Service Details" 
            />
            <ServiceDetailsContent />

            <Footer />
        </Layout>
    );
}

export default Details